<template>
  <footer class="m-auto w-full py-3 px-4 sm:px-6 mx-auto mt-8">
    <div class="flex justify-between items-center gap-5">
      <p class="text-xs">Made in Manchester by <a href="https://www.kevan.tv" target="_blank" class="underline">Matt Kevan</a>.</p>
      <p class="text-xs"><a href="https://github.com/MattKevan/minimal-reader/" target="_blank" class="underline">Github</a></p>
    </div>
  </footer>
</template>
<script>

export default {
  name: 'AppFooter'
}
</script>
<!-- Header.vue -->
<template>
  <header class="flex sticky top-0 justify-start flex-nowrap z-50 w-full bg-white/90  backdrop-blur-md text-sm py-3 dark:bg-black/90 ">
    <nav class="relative w-full px-4 items-center justify-between sm:px-6" aria-label="Global">
      <div class="flex items-center justify-between">
        <a class="text-lg font-semibold" href="#" aria-label="Brand">
          
          <svg width="80" viewBox="0 0 41 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.4443 19V12.7188H35.1553V13.8145H35.1904C35.3779 13.041 35.8877 12.5898 36.6201 12.5898C36.8076 12.5898 36.9834 12.6191 37.1123 12.6543V14.1602C36.9717 14.1016 36.7373 14.0664 36.4912 14.0664C35.6475 14.0664 35.1553 14.5703 35.1553 15.4844V19H33.4443Z" fill="currentColor"/>
            <path d="M29.9951 19.1289C28.0732 19.1289 26.9072 17.8809 26.9072 15.8711V15.8652C26.9072 13.8672 28.085 12.5898 29.9248 12.5898C31.7646 12.5898 32.9189 13.8379 32.9189 15.7363V16.2637H28.5947C28.6123 17.2656 29.1572 17.8516 30.0303 17.8516C30.7334 17.8516 31.1611 17.4648 31.2842 17.1133L31.2959 17.0781H32.8662L32.8486 17.1426C32.6553 18.0684 31.7881 19.1289 29.9951 19.1289ZM29.9541 13.8672C29.251 13.8672 28.7236 14.3359 28.6123 15.1855H31.2783C31.1729 14.3184 30.6631 13.8672 29.9541 13.8672Z" fill="currentColor"/>
            <path d="M22.6738 19.1289C21.0918 19.1289 20.1191 17.8984 20.1191 15.8594V15.8535C20.1191 13.8086 21.0918 12.5898 22.6738 12.5898C23.5645 12.5898 24.2676 13.0469 24.6074 13.8086H24.6426V10.5449H26.3535V19H24.6426V17.9277H24.6074C24.2734 18.6719 23.582 19.1289 22.6738 19.1289ZM23.248 17.7109C24.0801 17.7109 24.6484 16.9961 24.6484 15.8594V15.8535C24.6484 14.7227 24.0742 14.0078 23.248 14.0078C22.3984 14.0078 21.8594 14.7051 21.8594 15.8535V15.8594C21.8594 17.002 22.3926 17.7109 23.248 17.7109Z" fill="currentColor"/>
            <path d="M16.0029 19.0996C14.7432 19.0996 13.8936 18.3086 13.8936 17.2012V17.1895C13.8936 16.0469 14.7783 15.3789 16.3369 15.2793L17.8896 15.1855V14.7988C17.8896 14.2422 17.5264 13.8965 16.8584 13.8965C16.2197 13.8965 15.833 14.1953 15.751 14.5996L15.7393 14.6523H14.1748L14.1807 14.582C14.2744 13.4219 15.2705 12.5898 16.9287 12.5898C18.5518 12.5898 19.6006 13.4277 19.6006 14.6934V19H17.8896V18.0625H17.8545C17.4971 18.707 16.8291 19.0996 16.0029 19.0996ZM15.5869 17.1133C15.5869 17.5762 15.9678 17.8516 16.5596 17.8516C17.3271 17.8516 17.8896 17.3594 17.8896 16.7031V16.252L16.5947 16.334C15.9326 16.375 15.5869 16.6562 15.5869 17.1016V17.1133Z" fill="currentColor"/>
            <path d="M10.6846 19.1289C8.76269 19.1289 7.59668 17.8809 7.59668 15.8711V15.8652C7.59668 13.8672 8.77441 12.5898 10.6143 12.5898C12.4541 12.5898 13.6084 13.8379 13.6084 15.7363V16.2637H9.28418C9.30176 17.2656 9.84668 17.8516 10.7197 17.8516C11.4229 17.8516 11.8506 17.4648 11.9736 17.1133L11.9854 17.0781H13.5557L13.5381 17.1426C13.3447 18.0684 12.4775 19.1289 10.6846 19.1289ZM10.6436 13.8672C9.94043 13.8672 9.41309 14.3359 9.30176 15.1855H11.9678C11.8623 14.3184 11.3525 13.8672 10.6436 13.8672Z" fill="currentColor"/>
            <path d="M0.703125 19V10.5449H4.19531C6.09375 10.5449 7.21875 11.5527 7.21875 13.252V13.2637C7.21875 14.377 6.62695 15.3027 5.68945 15.6719L7.4707 19H5.4668L3.89062 15.9355H2.47266V19H0.703125ZM2.47266 14.5938H4.00195C4.88086 14.5938 5.4082 14.0957 5.4082 13.2637V13.252C5.4082 12.4434 4.85156 11.9277 3.97852 11.9277H2.47266V14.5938Z" fill="currentColor"/>
            <path d="M38.4102 9V0.544922H40.1211V9H38.4102Z" fill="currentColor"/>
            <path d="M34.0244 9.09961C32.7646 9.09961 31.915 8.30859 31.915 7.20117V7.18945C31.915 6.04688 32.7998 5.37891 34.3584 5.2793L35.9111 5.18555V4.79883C35.9111 4.24219 35.5479 3.89648 34.8799 3.89648C34.2412 3.89648 33.8545 4.19531 33.7725 4.59961L33.7607 4.65234H32.1963L32.2021 4.58203C32.2959 3.42188 33.292 2.58984 34.9502 2.58984C36.5732 2.58984 37.6221 3.42773 37.6221 4.69336V9H35.9111V8.0625H35.876C35.5186 8.70703 34.8506 9.09961 34.0244 9.09961ZM33.6084 7.11328C33.6084 7.57617 33.9893 7.85156 34.5811 7.85156C35.3486 7.85156 35.9111 7.35938 35.9111 6.70312V6.25195L34.6162 6.33398C33.9541 6.375 33.6084 6.65625 33.6084 7.10156V7.11328Z" fill="currentColor"/>
            <path d="M22.1553 9V2.71875H23.8662V3.77344H23.9014C24.1592 3.05859 24.8154 2.58984 25.6592 2.58984C26.5381 2.58984 27.1768 3.05859 27.3877 3.88477H27.4229C27.7041 3.09375 28.4541 2.58984 29.374 2.58984C30.6045 2.58984 31.4307 3.43945 31.4307 4.70508V9H29.7197V5.13281C29.7197 4.40625 29.3564 4.00781 28.6943 4.00781C28.0439 4.00781 27.6162 4.48242 27.6162 5.17969V9H25.9697V5.08008C25.9697 4.40625 25.5889 4.00781 24.9561 4.00781C24.3057 4.00781 23.8662 4.5 23.8662 5.20898V9H22.1553Z" fill="currentColor"/>
            <path d="M20.5117 1.91016C19.9961 1.91016 19.5977 1.50586 19.5977 1.01953C19.5977 0.533203 19.9961 0.128906 20.5117 0.128906C21.0273 0.128906 21.4258 0.533203 21.4258 1.01953C21.4258 1.50586 21.0273 1.91016 20.5117 1.91016ZM19.6562 9V2.71875H21.3672V9H19.6562Z" fill="currentColor"/>
            <path d="M13.0674 9V2.71875H14.7783V3.75H14.8135C15.1357 3.0293 15.7803 2.58984 16.7295 2.58984C18.1123 2.58984 18.8975 3.47461 18.8975 4.94531V9H17.1865V5.29688C17.1865 4.47656 16.7998 4.00781 16.0322 4.00781C15.2646 4.00781 14.7783 4.57031 14.7783 5.39062V9H13.0674Z" fill="currentColor"/>
            <path d="M11.4229 1.91016C10.9072 1.91016 10.5088 1.50586 10.5088 1.01953C10.5088 0.533203 10.9072 0.128906 11.4229 0.128906C11.9385 0.128906 12.3369 0.533203 12.3369 1.01953C12.3369 1.50586 11.9385 1.91016 11.4229 1.91016ZM10.5674 9V2.71875H12.2783V9H10.5674Z" fill="currentColor"/>
            <path d="M0.703125 9V0.544922H2.74805L5.15039 6.62109H5.18555L7.58789 0.544922H9.63281V9H8.05664V3.28125H8.00391L5.72461 9H4.61133L2.32617 3.28125H2.2793V9H0.703125Z" fill="currentColor"/>
          </svg>

        
        </a>
        <div @click="triggerUpload" style="cursor: pointer;" class="upload-button flex items-center font-medium text-gray-500 hover:text-blue-600 sm:ms-4  sm:ps-6 dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-blue-500">
            <input type="file" @change="handleFileChange" id="bookInput" multiple style="display: none" />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>

            Add books
          </div>
      </div>
      
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',  
  methods: {
    triggerUpload() {
      document.getElementById('bookInput').click();
    },
    handleFileChange(event) {
      this.$emit('upload-books', event);
    }
  }
}
</script>